<template>
  <div class="products">
    <Breadcrumb class="d-none d-sm-flex px-0" :items="breadcrumb" />
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <v-container>
      <CategoryTitle :category="category" :count="count" />

      <div
        v-if="!$ebsn.meta(category, 'template_model.HIDE_FILTERS', false)"
        class="d-flex"
      >
        <v-select
          v-for="articleClass in articleClasses"
          v-model="selectedArticleClasses[articleClass.parameterName]"
          dense
          :label="articleClass.name"
          :key="articleClass.id"
          :items="articleClass.values"
          item-text="name"
          item-value="id"
          @change="handleFilterChange"
          class="mr-2"
          clearable
          outlined
          return-object
        >
        </v-select>
      </div>
      <v-row
        ><v-col
          cols="12"
          sm="6"
          :md="showRightColumn ? 6 : 4"
          :lg="showRightColumn ? 4 : 3"
          :xl="showRightColumn ? 3 : 2"
          v-for="article in articleList"
          :key="article.articleId"
        >
          <ArticleCard :article="article" /> </v-col
      ></v-row>
      <v-pagination
        v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
        :page="pager.selPage"
        :length="pager.totPages ? pager.totPages : 0"
        :totalVisible="6"
        color="secondary"
        @next="handlePageChange"
        @prev="handlePageChange"
        @input="handlePageChange"
      ></v-pagination>
    </v-container>
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import ArticleCard from "@/components/article/ArticleCard.vue";

import CategoryTitle from "@/components/category/CategoryTitle.vue";

import cmService from "~/service/mksCmService";
import categoryMixins from "~/mixins/category";

export default {
  components: {
    Breadcrumb,
    CategoryTitle,
    ArticleCard
  },
  name: "ArticleClassGrid",
  mixins: [categoryMixins],
  data() {
    return {
      articleList: [],
      articleClasses: [],
      pager: null,
      page: 0,
      count: 0,
      selectedArticleClasses: {}
    };
  },
  computed: {
    showRightColumn() {
      return this.$ebsn.meta(this.category, "template_model.SHOW_RIGHT_COLUMN");
    }
  },
  methods: {
    async reload() {
      let parentArticleTypeId = this.$ebsn.meta(
        this.category,
        "template_model.ARTICLE_TYPE"
      );
      let parentArticleClassId = this.$ebsn.meta(
        this.category,
        "template_model.ARTICLE_CLASS_FILTER"
      );
      let params = {
        parent_article_type_id: parentArticleTypeId,
        parent_article_class_id: parentArticleClassId,
        page_size: 24
      };
      for (let key in this.$route.query) {
        params[key] = this.$route.query[key];
      }
      // if (this.$route.query["article_class_id"]) {
      //   params["article_class_id"] = this.$route.query["article_class_id"];
      // }
      // if (this.$route.query.page) {
      //   params["page"] = this.$route.query.page;
      // }
      let content = await cmService.searchArticle(params);
      this.articleList = content?.data?.articles;
      this.pager = content?.data?.page;
      this.articleClasses = content?.data?.facets;
      this.key++;
    },
    handlePageChange(page) {
      // let queryParam = Object.assign(this.$route.query, { page: page });
      // this.$router.push({
      //   path: this.$route.path,
      //   query: queryParam
      // });
      const query = { ...this.$route.query, page: page };
      this.$router.push({ query });
    },

    handleFilterChange(filter) {
      console.log(filter);
      console.log(this.selectedArticleClasses);
      let to = {
        // name: this.$route.name,
        query: { page: this.$route.query.page }
      };
      for (let key in this.selectedArticleClasses) {
        if (this.selectedArticleClasses[key]) {
          to.query[key] = this.selectedArticleClasses[key].id;
        }
      }
      // const query = { ...this.$route.query, article_class_id: filter.id };
      this.$router.push(to);
      //this.reload();
    }
  },
  async created() {
    await this.reload();
    //set selected values
    for (let key in this.$route.query) {
      this.selectedArticleClasses[key] = this.$route.query[key];
    }
  },
  watch: {
    "$route.query": function() {
      this.reload();
    }
  }
};
</script>
